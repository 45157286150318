import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "form-cell"
}
const _hoisted_4 = { class: "form-cell flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_6 = { class: "form-row" }
const _hoisted_7 = { class: "form-cell flex-none" }
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = { class: "form-cell flex-none" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getValidators, ValidationField } from './validations';
import HoForm from "@/components/form/ho-form.vue";
import HoText from "@/components/form/ho-text.vue";
import Toggle from '@/components/form/toggle.vue';
import IUser from '@/interfaces/users/iUser';
import DeactivateReasonFrom from '../deactivateReasonForm/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
	userData: {
		type: Object as () => IUser,
	},
	loading: Boolean,
	editMode: Boolean,
},
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const validators = getValidators(localization.t);

const props = __props;

const emit = __emit;

const formData = ref({
	userName: props.userData?.userName ?? '',
	password: '',
	email: props.userData?.email ?? '',
	active: props.userData?.active ?? true,
	deactivationReason: props.userData?.deactivationReason
});

const validState = ref({
	password: props.editMode ? true : false,
	userName: props.userData?.userName ?? false,
	email: props.userData?.email ?? false
} as { [key: string]: any });

const isValid = computed(() => {
	const isEmptyPasswordValid = props.editMode && formData.value.password?.length === 0;
	return !props.loading
		&& (validState.value.password || isEmptyPasswordValid)
		&& validState.value.userName
		&& validState.value.email
});

const handleChangeCancel = () => { emit('onClose'); };

const handleChangeSave = () => { emit('onClose', formData.value); };

const handleCheckboxChange = (value: boolean) => {
	formData.value.active = value;
};

const handleDeactivateReasonChange = (value: string) => {
	formData.value.deactivationReason = value;
};

const handleValidationStateChange = (fieldKey: ValidationField, value: boolean) => {
	validState.value[fieldKey] = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoForm, {
    class: "form-gray mt-4",
    modelValue: formData.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData).value = $event)),
    disableAutocomplete: true,
    onOnOk: handleChangeSave,
    onOnCancel: handleChangeCancel,
    okButtonText: __props.loading ? 'loading' : 'Save',
    cancelButtonText: "Cancel",
    disableOk: !isValid.value || __props.loading
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (__props.editMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[5] || (_cache[5] = _createElementVNode("label", null, "Status", -1)),
                _createVNode(Toggle, {
                  class: "mt-7 ml-0 mr-4",
                  label: "Active",
                  name: "active",
                  checked: __props.userData?.active,
                  onOnChange: _cache[0] || (_cache[0] = ($event: any) => (handleCheckboxChange($event)))
                }, null, 8, ["checked"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(HoText, {
              label: "Username",
              modelValue: formData.value.userName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.userName) = $event)),
              "disable-autocomplete": true,
              validation: _unref(validators).userName,
              modelKey: "userName",
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["modelValue", "validation"])
          ])
        ]),
        (formData.value.active === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(DeactivateReasonFrom, {
                selection: formData.value.deactivationReason,
                onOnChange: handleDeactivateReasonChange
              }, null, 8, ["selection"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(HoText, {
            label: "Email",
            modelValue: formData.value.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.email) = $event)),
            "disable-autocomplete": true,
            validation: _unref(validators).email,
            modelKey: "email",
            onOnValidStateChange: handleValidationStateChange
          }, null, 8, ["modelValue", "validation"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(HoText, {
            label: "Password",
            modelValue: formData.value.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.password) = $event)),
            "disable-autocomplete": true,
            validation: _unref(validators).password,
            isMasked: true,
            modelKey: "password",
            onOnValidStateChange: handleValidationStateChange
          }, null, 8, ["modelValue", "validation"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "okButtonText", "disableOk"]))
}
}

})