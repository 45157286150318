<template>
	<router-view v-if="!loading.user" :user="userData" @onReloadUser="handleReloadMyUser" />
</template>

<script setup lang="ts">
import "mosha-vue-toastify/dist/style.css";
import { onMounted, ref } from "vue";
import router from "./router";
import { isAuthenticated } from "@/services/authService";
import { getMyUser } from "@/services/userApi";
import IUser from "./interfaces/users/iUser";
import { useExecuteAsync } from "@/composables/useExecuteAsync";

type LoadingFields = "user";

const { loading, executeAsync } = useExecuteAsync();

const userData = ref({} as IUser);


const processMyUser = async () => {
	await executeAsync("user", async () => {
		const user = await getMyUser();

		if (user) {
			userData.value = user;
		} else {
			router.push("/login");
		}
	});
};

const initSessionData = async () => {
	await executeAsync<void, LoadingFields>("user", async () => {
		const isAuth = isAuthenticated();
		if (!isAuth) {
			router.push("/login");
		} else {
			processMyUser()
		}
	});
};

onMounted(initSessionData);

const handleReloadMyUser = async (callback: () => void) => {
	await executeAsync<void, LoadingFields>("user", async () => {
		await processMyUser();

		if (callback) {
			callback();
		}
	});
};
</script>
