<template>
	<div class="inline-flex gap-2 items-center">
		<label class="relative flex cursor-pointer" :for="inputId">
			<input type="radio" :id="inputId" :value="value" v-model="vModelInternal" />
			<span
				class="absolute bg-peri-600 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
			</span>
		</label>
		<label class="text-slate-600 cursor-pointer" :for="inputId">{{ label }}</label>
	</div>
</template>

<script lang="ts" setup>
import { computed, WritableComputedRef } from 'vue';

interface IHoRadio {
	label?: string,
	value?: string | number,
	modelValue?: string | number,
	modelKey: string,
	disabled?: boolean,
	id?: string
	key?: number | string
}

const props = defineProps<IHoRadio>();
const emit = defineEmits(['update:modelValue', 'onChange']);

const inputId = (props.id ?? props.value ?? `ho-input-${(props.key || "").toString}`).toString();

const vModelInternal: WritableComputedRef<string | number | undefined> = computed({
	get: () => {
		return props.modelValue;
	},
	set: (val) => {
		emit('update:modelValue', props.modelKey, val);
		emit('onChange', val);
	}
});
</script>

<style lang="postcss" scoped>
.input-radio label input {
	@apply h-5 w-5 cursor-pointer appearance-none rounded-full border border-peri-300 checked:border-peri-400 transition-all
}
</style>