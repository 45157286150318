import router from "@/router";
import { ApiError } from "@/types/errorTypes";
import { errorToast } from "@/utils/toastr";
import { Ref, ref } from "vue";

interface IExecuteAsyncResponse {
	loading: Ref<ILoadingField>;
	executeAsync: <R, L>(
		loadingField: L,
		exec: () => Promise<R>,
		showToast?: boolean
	) => Promise<R | undefined>;
}

interface ILoadingField {
	[key: string]: boolean;
}

export const useExecuteAsync = (): IExecuteAsyncResponse => {
	const loading: Ref<ILoadingField> = ref({});
	let result = undefined;

	const executeAsync = async <R, L>(
		loadingField: L,
		exec: () => Promise<R>,
		showToast = true
	) => {
		loading.value[loadingField as string] = true;

		try {
			result = (await exec()) as R | undefined;
			loading.value[loadingField as string] = false;
			return result;
		} catch (error) {
			const err = error as ApiError;

			if (err.statusCode === 401) {
				router.push("/login");
			} else {
				showToast && errorToast(err, err.message);

				if (
					process.env.NODE_ENV !== "production" &&
					showToast &&
					err.innerMessage
				) {
					errorToast(err, err.innerMessage);
				}

				throw error;
			}
		} finally {
			loading.value[loadingField as string] = false;
		}
	};

	return { loading, executeAsync };
};
