import { Composer } from "vue-i18n";
import { i18n } from "@/i18n";
import { post, setSessionId, setAuthenticationToken, put } from "./apiService";
import { AxiosResponse } from "axios";
import { ApiError } from "@/types/errorTypes";
interface ISession {
	odoo_user: string;
	session_id: number;
}

const localization: Composer = i18n.global;

export const login = async (
	userName: string,
	password: string
): Promise<void> => {
	const path = "auth/login";
	const body = {
		userName,
		password,
	};

	const result = await post<any>(path, body);
	if (result.status !== 200) {
		const errorMessage = localization.t("errors.cannot-connect-server", {
			status: result.status,
			statusText: result.statusText,
		});
		throw new Error(errorMessage);
	}

	if (!result.data) {
		const errorMessage = localization.t("errors.cannot-get-server-data");
		throw new Error(errorMessage);
	}
	storeAuthenticationToken(result.data.accessToken);
	setAuthenticationToken(result.data.accessToken);
	removeSession();
};

export const logout = (): void => {
	removeSession();
	removeAuthenticationToken();
};

export const isAuthenticated = (): boolean => {
	if (localStorage.getItem("accessToken")) {
		const accessToken = localStorage.getItem("accessToken");
		setAuthenticationToken(accessToken);
		return true;
	}
	return false;
};

export const changeMyPassword = async (
	oldPassword: string,
	newPassword: string
): Promise<void> => {
	const path = "auth/password";
	const body = {
		old: oldPassword,
		new: newPassword,
	};
	const result = await put<any>(path, body);
	if (result.status !== 200) {
		const errorMessage = localization.t("errors.cannot-connect-server", {
			status: result.status,
			statusText: result.statusText,
		});
		throw new Error(errorMessage);
	}
};

export const getSession = (): ISession | null => {
	if (localStorage.getItem("odooSessionId")) {
		const sessionUser = localStorage.getItem("odooSessionUser");
		const sessionId = Number(localStorage.getItem("odooSessionId"));

		if (Number.isNaN(sessionId)) {
			removeSession();
		} else {
			const sessionData: ISession = {
				odoo_user: sessionUser ? sessionUser : "",
				session_id: Number(localStorage.getItem("odooSessionId")),
			};
			setSessionId(sessionData.session_id);
			return sessionData;
		}
	}
	return null;
};

export const syncSession = async (db: string): Promise<ISession> => {
	const path = `odoo/connect`;
	const body = { db };
	try {
		const result = await post<ISession>(path, body, null, {
			headers: {
				"Content-Type": "application/json",
			},
		});

		verifyConnectResult(result);
		removeSession();

		storeSession(result.data);
		setSessionId(result.data.session_id);

		return result.data;
	} catch (error) {
		let err = error as ApiError;
		if (err.statusCode === 400) {
			err = new ApiError(
				localization.t("errors.codes.112"),
				err.innerMessage,
				err.statusCode
			);
		}

		throw err;
	}
};

const verifyConnectResult = (result: AxiosResponse<ISession>) => {
	if (result.status !== 200) {
		const errorMessage = localization.t("errors.cannot-connect-server", {
			status: result.status,
			statusText: result.statusText,
		});
		throw new Error(errorMessage);
	}

	if (!result.data) {
		const errorMessage = localization.t("errors.cannot-get-server-data");
		throw new Error(errorMessage);
	}

	if (!result.data.session_id) {
		const errorMessage = localization.t("errors.cannot-get-session-data");
		throw new Error(errorMessage);
	}
};

const storeSession = (sessionData: ISession) => {
	localStorage.setItem("odooSessionId", sessionData.session_id.toString());
};

const storeAuthenticationToken = (accessToken: string) => {
	localStorage.setItem("accessToken", accessToken);
};
const removeAuthenticationToken = () => {
	localStorage.removeItem("accessToken");
	setAuthenticationToken(null);
};
const removeSession = (): void => {
	localStorage.removeItem("odooSessionId");
	setSessionId(null);
};
