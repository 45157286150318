import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "deactivate-reason-selector" }
const _hoisted_2 = { class: "form-cell" }
const _hoisted_3 = {
  key: 0,
  class: "form-cell"
}

import HoRadio from '@/components/form/ho-radio.vue';
import HoText from '@/components/form/ho-text.vue';
import { CUSTOM_DEACTIVATION_REASON, PREDEFINED_DEACTIVATE_REASONS } from '@/constants/user';
import { computed, Ref, ref } from 'vue';

interface IDefineReasonsFormProps {
	selection?: string,
}

const modelKey = 'deactivationReason';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    selection: {}
  },
  emits: ['onChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const mapReason = (reason?: string) => {
	return PREDEFINED_DEACTIVATE_REASONS.find(r => r.value === reason)?.value ?? 'CUSTOM'
};

const mappedReason = computed(() => mapReason(props.selection));

const selectedReason: Ref<string | undefined> = ref(mappedReason.value);
const selectedCustomReason: Ref<boolean> = ref(mappedReason.value === CUSTOM_DEACTIVATION_REASON);
const customReasonText: Ref<string | undefined> = ref(mappedReason.value === CUSTOM_DEACTIVATION_REASON ? props.selection : undefined);
const handleRadioChange = (value: string) => {
	selectedReason.value = value;

	if (value !== CUSTOM_DEACTIVATION_REASON) {
		if (selectedCustomReason.value === true) {
			selectedCustomReason.value = false;
			customReasonText.value = "";
		}

		emit('onChange', value);
	} else {
		selectedCustomReason.value = true;
	}
};

const handleCustomReasonTextChange = (textValue: string) => emit('onChange', textValue);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PREDEFINED_DEACTIVATE_REASONS), (reason, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "form-cell",
        key: index
      }, [
        _createVNode(HoRadio, {
          label: reason.label,
          modelValue: selectedReason.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedReason).value = $event)),
          value: reason.value,
          modelKey: modelKey,
          onOnChange: handleRadioChange
        }, null, 8, ["label", "modelValue", "value"])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HoRadio, {
        label: "Custom",
        modelValue: selectedReason.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedReason).value = $event)),
        value: _unref(CUSTOM_DEACTIVATION_REASON),
        modelKey: modelKey,
        onOnChange: handleRadioChange
      }, null, 8, ["modelValue", "value"])
    ]),
    (selectedCustomReason.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(HoText, {
            modelValue: customReasonText.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((customReasonText).value = $event)),
              handleCustomReasonTextChange
            ],
            modelKey: modelKey
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})