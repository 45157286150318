import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex gap-2 items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = ["for"]

import { computed, WritableComputedRef } from 'vue';

interface IHoRadio {
	label?: string,
	value?: string | number,
	modelValue?: string | number,
	modelKey: string,
	disabled?: boolean,
	id?: string
	key?: number | string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ho-radio',
  props: {
    label: {},
    value: {},
    modelValue: {},
    modelKey: {},
    disabled: { type: Boolean },
    id: {},
    key: {}
  },
  emits: ['update:modelValue', 'onChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const inputId = (props.id ?? props.value ?? `ho-input-${(props.key || "").toString}`).toString();

const vModelInternal: WritableComputedRef<string | number | undefined> = computed({
	get: () => {
		return props.modelValue;
	},
	set: (val) => {
		emit('update:modelValue', props.modelKey, val);
		emit('onChange', val);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "relative flex cursor-pointer",
      for: _unref(inputId)
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "radio",
        id: _unref(inputId),
        value: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vModelInternal).value = $event))
      }, null, 8, _hoisted_3), [
        [_vModelRadio, vModelInternal.value]
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute bg-peri-600 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" }, null, -1))
    ], 8, _hoisted_2),
    _createElementVNode("label", {
      class: "text-slate-600 cursor-pointer",
      for: _unref(inputId)
    }, _toDisplayString(_ctx.label), 9, _hoisted_4)
  ]))
}
}

})