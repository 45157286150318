import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import "mosha-vue-toastify/dist/style.css";
import { onMounted, ref } from "vue";
import router from "./router";
import { isAuthenticated } from "@/services/authService";
import { getMyUser } from "@/services/userApi";
import IUser from "./interfaces/users/iUser";
import { useExecuteAsync } from "@/composables/useExecuteAsync";

type LoadingFields = "user";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { loading, executeAsync } = useExecuteAsync();

const userData = ref({} as IUser);


const processMyUser = async () => {
	await executeAsync("user", async () => {
		const user = await getMyUser();

		if (user) {
			userData.value = user;
		} else {
			router.push("/login");
		}
	});
};

const initSessionData = async () => {
	await executeAsync<void, LoadingFields>("user", async () => {
		const isAuth = isAuthenticated();
		if (!isAuth) {
			router.push("/login");
		} else {
			processMyUser()
		}
	});
};

onMounted(initSessionData);

const handleReloadMyUser = async (callback: () => void) => {
	await executeAsync<void, LoadingFields>("user", async () => {
		await processMyUser();

		if (callback) {
			callback();
		}
	});
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (!_unref(loading).user)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        user: userData.value,
        onOnReloadUser: handleReloadMyUser
      }, null, 8, ["user"]))
    : _createCommentVNode("", true)
}
}

})