<template>
	<div class="deactivate-reason-selector">
		<div class="form-cell" v-for="(reason, index) in PREDEFINED_DEACTIVATE_REASONS" :key="index">
			<HoRadio :label="reason.label" v-model="selectedReason" :value="reason.value" :modelKey="modelKey"
				@on-change="handleRadioChange" />
		</div>
		<div class="form-cell">
			<HoRadio label="Custom" v-model="selectedReason" :value="CUSTOM_DEACTIVATION_REASON" :modelKey="modelKey"
				@on-change="handleRadioChange" />
		</div>
		<div class="form-cell" v-if="selectedCustomReason">
			<HoText v-model="customReasonText" :modelKey="modelKey" @update:modelValue="handleCustomReasonTextChange" />
		</div>
	</div>
</template>

<script lang="ts" setup>
import HoRadio from '@/components/form/ho-radio.vue';
import HoText from '@/components/form/ho-text.vue';
import { CUSTOM_DEACTIVATION_REASON, PREDEFINED_DEACTIVATE_REASONS } from '@/constants/user';
import { computed, Ref, ref } from 'vue';

interface IDefineReasonsFormProps {
	selection?: string,
}

const props = defineProps<IDefineReasonsFormProps>();
const emit = defineEmits(['onChange']);

const mapReason = (reason?: string) => {
	return PREDEFINED_DEACTIVATE_REASONS.find(r => r.value === reason)?.value ?? 'CUSTOM'
};

const mappedReason = computed(() => mapReason(props.selection));

const selectedReason: Ref<string | undefined> = ref(mappedReason.value);
const selectedCustomReason: Ref<boolean> = ref(mappedReason.value === CUSTOM_DEACTIVATION_REASON);
const customReasonText: Ref<string | undefined> = ref(mappedReason.value === CUSTOM_DEACTIVATION_REASON ? props.selection : undefined);
const modelKey = 'deactivationReason';

const handleRadioChange = (value: string) => {
	selectedReason.value = value;

	if (value !== CUSTOM_DEACTIVATION_REASON) {
		if (selectedCustomReason.value === true) {
			selectedCustomReason.value = false;
			customReasonText.value = "";
		}

		emit('onChange', value);
	} else {
		selectedCustomReason.value = true;
	}
};

const handleCustomReasonTextChange = (textValue: string) => emit('onChange', textValue);

</script>