<template>
	<div class="ho-app">
		<div class="wrapper" :class="{ initial: !firstFilterTriggered }">
			<div class="header">
				<div class="header-part header-part-left">
					<div class="menu-label whitespace-nowrap -mr-2">
						<label class="font-bold">{{
							localization.t("app.odoo-connection")
						}}</label>
					</div>

					<div class="status menu-item menu-item-left menu-item-dropdown" :class="{
						connected: activeConnection,
						disconnected: !activeConnection,
					}">
						<span class="status-text">
							<div class="h-8 w-8 inline-block"><span class="dot" /></div>
							{{
								(activeConnection &&
									(activeConnection.alias || activeConnection.db)) ||
								localization.t("app.not-connected")
							}}
						</span>

						<span>
							<chevron-up-icon class="dropdown-icon-up w-6" />
							<chevron-down-icon class="dropdown-icon-down w-6" />
						</span>

						<div class="dropdown">
							<ul>
								<li v-for="connection in user?.connections || []" :key="connection.db"
									@click="handleSelectConnection(connection)">
									<span class="w-8 h-8 text-center"></span>
									<label>{{ connection.alias || connection.db }}</label>
								</li>
								<li @click="showConnectionsDialog">
									<span class="w-8 h-8 text-center"><cog-icon class="w-6 h-6" /></span>
									<label>{{ localization.t("app.configs") }}</label>
								</li>
							</ul>
						</div>
					</div>

					<div v-if="firstFilterTriggered" class="user menu-item menu-item-dropdown menu-item-left"
						@click="handleImportModalOpen">
						<span><document-duplicate-icon class="h-8 w-8" /></span>
						<label class="mr-auto inline-block">{{ localization.t("home.import-convert") }}
							{{ localization.t("home.vat-file") }}</label>
					</div>
				</div>

				<div class="header-part header-part-right">
					<div v-if="user?.role === 'admin'" class="user menu-item menu-item-right menu-item-dropdown"
						@click="goToAdministration">
						<span class="w-8 h-8 text-center"><adjustments-icon class="w-6 h-6" /></span>
						<label>{{ localization.t("app.administration") }}</label>
					</div>
					<div class="user menu-item menu-item-dropdown menu-item-right">
						<span><user-circle-icon class="h-8 w-8" /></span>
						<label class="mr-auto inline-block">{{ fullName }}</label>
						<chevron-up-icon class="dropdown-icon-up w-6" />
						<chevron-down-icon class="dropdown-icon-down w-6" />

						<div class="dropdown min-w-96">
							<ul>
								<li @click="showMyProfileDialog">
									<span class="w-8 h-8 text-center"><pencil-alt-icon class="w-6 h-6" /></span><label>{{
										localization.t("app.my-profile") }}</label>
								</li>
								<li @click="showDeclarantDataDialog">
									<span class="w-8 h-8 text-center"><pencil-alt-icon class="w-6 h-6" /></span><label>{{
										localization.t("app.declarant-data") }}</label>
								</li>
								<li @click="handleLogout">
									<span class="w-8 h-8 text-center"><logout-icon class="w-6 h-6" /></span><label>{{
										localization.t("app.logout") }}</label>
								</li>
							</ul>
						</div>
					</div>

					<div class="user menu-item menu-item-dropdown menu-item-right">
						<span><language-flags class="h-8 w-8 mr-2 rounded-full"
								:selectedLanguage="localization.locale.value" /></span>
						<chevron-up-icon class="dropdown-icon-up w-6" />
						<chevron-down-icon class="dropdown-icon-down w-6" />

						<div class="dropdown min-w-96">
							<locale-switch />
						</div>
					</div>
				</div>
			</div>

			<declarant-data-dialog :profileData="user?.profileData" v-if="declarantDataDialogOpen"
				@onUpdateDeclarantClose="handleDeclarantDataDialogClose" />
			<user-data-dialog :userData="user" :loading="loading.password" v-if="myProfileDialogOpen"
				@onChangePasswordClose="handleChangePasswordDialogClose" />

			<connections-dialog v-if="connectionsDialogOpen" :loading="loading" :connections="[...(user?.connections || [])]"
				@onConnectionsDialogClose="handleConnectionsDialogClose" @onEditConnectionsClose="handleEditConnectionsClose" />
			<import-external-invoices v-if="importModalOpen" :declarantData="profileData"
				@onUploadModalClose="handleImportModalClose" :importResult="importResult" :loading="loading.import"
				:companies="companies" />

			<loading-spinner v-if="loading.connect || loading.user || loading.companies" />

			<Teleport :to="APP_TELEPORT_CONTAINER_ID">
				<!-- <app-teleport-container :hide-footer="true" header-text="foo">
          <template v-slot:teleport-body>
            
          </template>
</app-teleport-container> -->
			</Teleport>

			<router-view v-if="user?.active" class="ho-app" :user="user" :connection="activeConnection" :companies="companies"
				@onImportModalOpen="handleImportModalOpen" @onOpenConnections="showConnectionsDialog"
				@onFirstFilterTriggered="handleFirstFilterTriggered" />

			<div v-if="userError" class="centered-message">
				<h2><strong>{{ userError.title }}</strong></h2>
				<p>{{ userError.body }}</p>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, Ref, toRaw, watch } from "vue";
import { useI18n } from "vue-i18n";
import router from "../router";
import DeclarantDataDialog from "@/components/profile/declarant-data-dialog.vue";
import { changeMyPassword, logout, syncSession } from "@/services/authService";
import { getAll as getAllCompanies } from "@/services/companiesApi";
import ConnectionsDialog from "@/components/app/connections-dialog.vue";
import {
	editUserConnections,
	getActiveConnection,
	switchConnection,
	updateMyProfile,
} from "@/services/userApi";
import {
	UserCircleIcon,
	PencilAltIcon,
	LogoutIcon,
	CogIcon,
	DocumentDuplicateIcon,
	AdjustmentsIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import IProfile from "@/interfaces/users/iProfile";
import IUser from "@/interfaces/users/iUser";
import IConnection from "@/interfaces/users/iConnection";
import { useExecuteAsync } from "@/composables/useExecuteAsync";
import UserDataDialog from "@/components/profile/user-data-dialog.vue";
import LocaleSwitch from "@/components/app/locale-switch.vue";
import LanguageFlags from "@/components/svg/language-flags.vue";
import LoadingSpinner from "@/components/loading-spinner.vue";
import { APP_TELEPORT_CONTAINER_ID } from "@/constants/app";
import ImportExternalInvoices from "@/components/home/import-external-invoices.vue";
import IConvertDeclarationResult from "@/interfaces/home/iConvertDeclarationResult";
import IDeclarationData from "@/interfaces/home/iDeclarationData";
import { importData } from "@/services/invoicesApi";
import ICompany from "@/interfaces/companies/iCompany";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";
import { PREDEFINED_DEACTIVATE_REASONS } from "@/constants/user";
const localization = useI18n();

const props = defineProps({
	user: {
		type: Object as () => IUser,
	},
});

const { loading, executeAsync } = useExecuteAsync();

const emit = defineEmits(["onReloadUser"]);

type LoadingFields =
	| "user"
	| "connection"
	| "connect"
	| "password"
	| "import"
	| "companies";

const companies: Ref<ICompany[]> = ref([]);
const companiesLoaded = ref(false);
const declarantDataDialogOpen = ref(false);
const myProfileDialogOpen = ref(false);
const connectionsDialogOpen = ref(false);

const importModalOpen = ref(false);
const importResult = ref<IConvertDeclarationResult>();
const firstFilterTriggered = ref(false);
const userError: Ref<{
	title: string,
	body: string
} | null> = ref(null);

const activeConnection = computed(() => {
	const activeConnection =
		props.user?.connections &&
		props.user?.connections.find((c) => c.active === true);
	return activeConnection;
});

const mapDeacitvatedReason = (reason?: string): string => {
	const predefinedReason = PREDEFINED_DEACTIVATE_REASONS.find(r => r.value === reason)?.value;
	return predefinedReason
		? localization.t(`common.profile.deactivatedReason.${predefinedReason}`)
		: reason ?? localization.t('errors.profile-inactive-default-reason');
};

const init = async () => {
	if (!props.user?.active) {

		userError.value = {
			title: localization.t('errors.codes.104'),
			body: mapDeacitvatedReason(props.user?.deactivationReason)
		}

		return;
	}

	if (!props.user?.connections || props.user?.connections.length === 0) {
		connectionsDialogOpen.value = true;
		return;
	}

	const activeConnection = getActiveConnection(props.user?.connections || []);

	if (!activeConnection) {
		connectionsDialogOpen.value = true;
		return;
	}

	try {
		await executeAsync<void, LoadingFields>("connect", async () => {
			await syncSession(activeConnection.db);
			await getCompanies();
		});
	} catch (error) {
		connectionsDialogOpen.value = true;
	}
};

onMounted(init);

watch(() => props.user?.id, (current, prev) => {
	if ((current && current.length > 0) && !prev) {
		init();
	}
});

const fullName = computed(() => {
	let fullNameLabel = props.user?.userName;
	if (props.user?.profileData) {
		if (props.user?.profileData.firstName) {
			fullNameLabel = `${props.user?.profileData.firstName}`;
		}

		if (props.user?.profileData.lastName) {
			fullNameLabel = `${fullNameLabel} ${props.user?.profileData.lastName}`;
		}
	}

	return fullNameLabel;
});

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile &
		IDeclarantExtended);
});

const getCompanies = async () => {
	await executeAsync<void, LoadingFields>("companies", async () => {
		const companiesResult = await getAllCompanies();
		companies.value = companiesResult;
		companiesLoaded.value = true;
	});
};

const showDeclarantDataDialog = async () => {
	declarantDataDialogOpen.value = true;
};
const showConnectionsDialog = () => {
	connectionsDialogOpen.value = true;
};

const showMyProfileDialog = () => {
	myProfileDialogOpen.value = true;
};

const handleDeclarantDataDialogClose = async (profileData: IProfile) => {
	if (profileData) {
		await executeAsync<void, LoadingFields>("user", async () => {
			await updateMyProfile(profileData);
			emit("onReloadUser");
			declarantDataDialogOpen.value = false;
		});
	} else {
		declarantDataDialogOpen.value = false;
	}
};

const handleChangePasswordDialogClose = async (
	oldPassword: string,
	newPassword: string
) => {
	if (oldPassword && newPassword) {
		await executeAsync<void, LoadingFields>("password", async () => {
			await changeMyPassword(oldPassword, newPassword);
			myProfileDialogOpen.value = false;
		});
	} else {
		myProfileDialogOpen.value = false;
	}
};

const handleLogout = () => {
	logout();
	location.replace("/login");
};

const handleSelectConnection = async (connection: IConnection) => {
	try {
		await executeAsync<void, LoadingFields>("connect", async () => {
			await switchConnection(connection.db);
			location.reload();
		});
	} catch (error) {
		connectionsDialogOpen.value = true;
	}
};

const handleConnectionsDialogClose = async (connection?: IConnection) => {
	if (connection) {
		try {
			await executeAsync<void, LoadingFields>("connect", async () => {
				await switchConnection(connection.db);
				location.reload();
				connectionsDialogOpen.value = false;
			});
		} catch (error) {
			connectionsDialogOpen.value = true;
		}
	} else {
		connectionsDialogOpen.value = false;
	}
};

const handleEditConnectionsClose = async (
	connections?: IConnection[],
	reconnect?: boolean,
	onSuccess?: () => void
) => {
	if (connections) {
		await executeAsync<void, LoadingFields>("connection", async () => {
			await editUserConnections(connections);
			emit("onReloadUser");

			if (reconnect) {
				const activeConnection = getActiveConnection(connections || []);

				if (!activeConnection) {
					connectionsDialogOpen.value = true;
					return;
				}

				const connectionDB = activeConnection?.db;
				if (connectionDB) {
					await executeAsync<void, LoadingFields>("connect", async () => {
						await switchConnection(connectionDB);
						onSuccess && onSuccess();
						connectionsDialogOpen.value = false;
					});
				}
			} else {
				onSuccess && onSuccess();
			}
		});
	}
};

const handleImportModalOpen = () => {
	importModalOpen.value = true;
};

const handleImportModalClose = async (
	uploadFile: File | null,
	declarationData: IDeclarationData
) => {
	if (uploadFile) {
		const formData = new FormData();
		formData.append("file", uploadFile);
		try {
			const downloadBLobResult = await executeAsync<IConvertDeclarationResult | undefined, LoadingFields>("import", async () => {
				return await importData(formData, declarationData);
			});
			importResult.value = downloadBLobResult;
		} catch (error) {
			importModalOpen.value = !importModalOpen.value;
		}
	} else {
		importModalOpen.value = !importModalOpen.value;
	}
};

const handleFirstFilterTriggered = () => {
	if (!firstFilterTriggered.value) {
		firstFilterTriggered.value = true;
	}
};

const goToAdministration = () => router.push("/admin/users");

</script>

<style lang="postcss" scoped>
.header {
	padding: 8px 24px 8px;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 2.4rem;
	height: 60px;
	background: rgba(237, 234, 245, 0.6);
	overflow: visible;

	@apply flex items-center;
}

.header .header-part {
	flex: 1 0 0;
	flex-wrap: nowrap;
	display: inline-flex;
	align-items: center;
}

.header .header-part.header-part-right {
	justify-content: flex-end;
}

.header .menu-item {
	@apply px-5 py-2 cursor-pointer whitespace-nowrap;
}

.header .menu-item.menu-item-dropdown:hover {
	@apply bg-peri-500 text-white rounded-md relative;
}

.header .menu-item label {
	@apply cursor-pointer;
}

.header .user svg {
	display: inline;
}

.header .user>label {
	font-weight: 800;
	@apply ml-2 mr-2 whitespace-nowrap;
}

.header .status {
	font-size: 1.4rem;
	@apply ml-4;
}

.header .status svg {
	display: inline;
}

.header .status>label {
	@apply ml-4 mr-2 whitespace-nowrap;
}

.header .status.connected .status-text label {
	@apply text-green-500 font-bold;
}

.header .status .dot {
	width: 12px;
	height: 12px;
	border-radius: 20px;
	display: inline-block;
	background: #aaa;
	margin-right: 4px;
	vertical-align: middle;
}

.header .status.connected .dot {
	background: rgb(80, 216, 2);
}

.header .menu-item .dropdown {
	display: none;
	position: absolute;
	min-width: 220px;
	z-index: 9998;
	@apply bg-peri-500 rounded-md -ml-5 pt-3;
}

.header .menu-item.menu-item-right .dropdown {
	right: 0;
}

.header .menu-item .dropdown-icon-up {
	display: none;
}

.header .menu-item .dropdown-icon-down {
	display: inline-block;
}

.header .menu-item:hover .dropdown {
	display: block;
}

.header .menu-item:hover .dropdown-icon-up {
	display: inline-block;
}

.header .menu-item:hover .dropdown-icon-down {
	display: none;
}

:deep(.header .menu-item .dropdown ul li) {
	@apply pl-4 pr-6 py-1 cursor-pointer;
}

:deep(.header .menu-item .dropdown ul li:hover) {
	@apply bg-peri-400;
}

:deep(.header .menu-item .dropdown ul li:last-child) {
	@apply rounded-b-md;
}

:deep(.header .menu-item .dropdown ul li > label) {
	text-wrap: wrap;
	@apply ml-3 cursor-pointer;
}

.ho-app {
	height: 100%;
	background: linear-gradient(142deg,
			rgb(214, 219, 240) 0%,
			rgba(234, 226, 241, 0.6) 46%,
			rgba(169, 169, 208, 0.8) 76%,
			rgba(169, 169, 208, 1) 86%,
			rgba(169, 169, 208, 1) 100%);
}

.ho-app .wrapper {
	height: calc(100% - 60px);
}


.centered-message {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

@media (max-height: 767px) {
	.wrapper.initial {
		position: absolute;
	}
}
</style>
