<template>
	<ho-form class="form-gray mt-4" v-model="formData" :disableAutocomplete="true" @onOk="handleChangeSave"
		@onCancel="handleChangeCancel" :okButtonText="loading ? 'loading' : 'Save'" cancelButtonText="Cancel"
		:disableOk="!isValid || loading">
		<template v-slot:body>
			<div class="form-row">
				<div class="flex justify-between">
					<div class="form-cell" v-if="editMode">
						<label>Status</label>
						<toggle class="mt-7 ml-0 mr-4" label="Active" name="active" :checked="userData?.active"
							@onChange="handleCheckboxChange($event)" />
					</div>

					<div class="form-cell flex-1">
						<ho-text label="Username" v-model="formData.userName" :disable-autocomplete="true"
							:validation="validators.userName" modelKey="userName" @onValidStateChange="handleValidationStateChange" />
					</div>
				</div>
				<div class="mt-4" v-if="formData.active === false">
					<DeactivateReasonFrom :selection="formData.deactivationReason" @onChange="handleDeactivateReasonChange" />
				</div>
			</div>
			<div class="form-row">
				<div class="form-cell flex-none">
					<ho-text label="Email" v-model="formData.email" :disable-autocomplete="true" :validation="validators.email"
						modelKey="email" @onValidStateChange="handleValidationStateChange" />
				</div>
			</div>
			<div class="form-row">
				<div class="form-cell flex-none">
					<ho-text label="Password" v-model="formData.password" :disable-autocomplete="true"
						:validation="validators.password" :isMasked="true" modelKey="password"
						@onValidStateChange="handleValidationStateChange" />
				</div>
			</div>
		</template>
	</ho-form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getValidators, ValidationField } from './validations';
import HoForm from "@/components/form/ho-form.vue";
import HoText from "@/components/form/ho-text.vue";
import Toggle from '@/components/form/toggle.vue';
import IUser from '@/interfaces/users/iUser';
import DeactivateReasonFrom from '../deactivateReasonForm/index.vue';

const localization = useI18n();

const validators = getValidators(localization.t);

const props = defineProps({
	userData: {
		type: Object as () => IUser,
	},
	loading: Boolean,
	editMode: Boolean,
});

const emit = defineEmits(['onClose']);

const formData = ref({
	userName: props.userData?.userName ?? '',
	password: '',
	email: props.userData?.email ?? '',
	active: props.userData?.active ?? true,
	deactivationReason: props.userData?.deactivationReason
});

const validState = ref({
	password: props.editMode ? true : false,
	userName: props.userData?.userName ?? false,
	email: props.userData?.email ?? false
} as { [key: string]: any });

const isValid = computed(() => {
	const isEmptyPasswordValid = props.editMode && formData.value.password?.length === 0;
	return !props.loading
		&& (validState.value.password || isEmptyPasswordValid)
		&& validState.value.userName
		&& validState.value.email
});

const handleChangeCancel = () => { emit('onClose'); };

const handleChangeSave = () => { emit('onClose', formData.value); };

const handleCheckboxChange = (value: boolean) => {
	formData.value.active = value;
};

const handleDeactivateReasonChange = (value: string) => {
	formData.value.deactivationReason = value;
};

const handleValidationStateChange = (fieldKey: ValidationField, value: boolean) => {
	validState.value[fieldKey] = value;
};
</script>

<style lang="postcss" scoped>
.form-gray {
	.form-row {
		.form-cell {
			margin-left: 0;
		}
	}
}

.form-row .form-cell label.ho-toggle-component {
	display: flex;
}
</style>
