export const PREDEFINED_DEACTIVATION_REASONS_VALUES = {
	MAX_EXPORTS: "MAX_EXPORTS",
	CONTRACT_EXPIRED: "CONTRACT_EXPIRED",
};

export const CUSTOM_DEACTIVATION_REASON = 'CUSTOM';

export const PREDEFINED_DEACTIVATE_REASONS = [
	{
		label: "Maximum number of unique exports reached",
		value: PREDEFINED_DEACTIVATION_REASONS_VALUES.MAX_EXPORTS,
	},
	{
		label: "User contract expired",
		value: PREDEFINED_DEACTIVATION_REASONS_VALUES.CONTRACT_EXPIRED,
	},
];